.autocomplete {
    display: inline-block;
    background: lightgray;
    min-width:32px;
    min-height:32px;
    margin-right: 5px;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        height: 32px;
        width: 32px;
        background-image: url(../../lib/missing.png);
    }
}
.noimg {
    display: inline-block;
    background: lightgray;
    min-width: 32px;
    min-height: 32px;
    margin-right: 5px;
}
.ml5 {
    margin-left: 5px;
}
.progressPlaceholder {
    height: 4px;
}
