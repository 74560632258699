.root {
  margin-top: 30px;
  display: flex!important;
  flex-flow: row wrap;
}
.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 40%;
  margin: 15px;
}
.fillHeight{
  flex-grow: 1;
}
.title {

}
.pos {

}
.centerMiddle{
  display: flex!important;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 90px);
  Alert {
    max-width: 500px;
    max-height: 200px;
    padding: 15px;
  }
  Card {
    max-width: 400px;
    max-height: 400px;
    padding: 15px;
  }
}