.backend {
  width: 170px;
  max-width: 170px;
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px 15px;
}
.webserver {
  width: 170px;
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px 15px;
}
.hash{
  font-size: 90%;
}