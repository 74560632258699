

.pb{
  padding-bottom: 45px;
}
.ml{
  margin-left: 10px;
}
.buildstatus {
  padding-bottom: 30px;
}

.centerMiddle {
  display: flex!important;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 90px);
}
.alert {
  max-width: 800px;
  max-height: 200px;
  padding: 15px;
}
.card {
  min-width: 150px;
  max-width: 800px;
  padding: 15px;
}

.lh24{
  line-height: 24px;
}
.inline {
  display: inline-block!important;
  width: 30%;
}
.center {
  display: flex!important;
  align-items: center;
  justify-content: center;

}
.text-updater-node {
  height: 50px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}